<template>
    <div class="main">
        <Sidebar active="pdf" />
        <div class="main-content">
            <b-modal
                v-model="openModal"
                class="custom-modal"
                hide-header
                hide-footer
                size="xl"
            >
                <div class="head-title">
                    <span class="text-title">Export des données</span
                    ><span class="text-description"
                        >Choisissez les pdfs à exporter
                    </span>
                </div>
                <div class="child-list export">
                    <div
                        class="child-item export"
                        v-for="(pdf, index) of pdfOptions"
                        :key="pdf.id"
                    >
                        <span class="item-text">{{ pdf.name }}</span>
                        <b-form-checkbox
                            v-model="pdfsSelect[index]"
                            value="true"
                            unchecked-value="false"
                        ></b-form-checkbox>
                    </div>
                </div>

                <div class="modal-button">
                    <b-button class="name-btn" v-on:click="downloadFile(1)"
                        >Télécharger</b-button
                    ><b-button
                        class="name-btn secondary"
                        v-on:click="openModal = false"
                        >Annuler</b-button
                    >
                </div></b-modal
            >
            <div class="left card">
                <div class="head-title">
                    <span class="text-title">Mes PDF</span
                    ><span class="text-description"
                        >Choissisez un modèle pour créer un nouveau pdf ou
                        exporter les pdfs
                    </span>
                </div>
                <div class="search-bar-section">
                    <b-form-input
                        placeholder="Search"
                        class="search-bar"
                    ></b-form-input>
                </div>
                <perfect-scrollbar>
                    <div class="list">
                        <div
                            v-for="(style, index) of styles"
                            :key="style.id"
                            class="group-item"
                        >
                            <div
                                class="item"
                                :class="{
                                    active: isActive[index],
                                    open: isOpen[index],
                                    base: !isOpen[index],
                                }"
                            >
                                <div class="item-left">
                                    <div v-if="isOpen[index] == true">
                                        <img
                                            :src="
                                                './template' +
                                                style.template +
                                                '.png'
                                            "
                                            class="item-img"
                                        />
                                    </div>
                                    <div class="text-circle">
                                        <div class="item-text">
                                            Template {{ style.template }} -
                                            {{ style.name }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="item-buttons"
                                    v-if="
                                        isOpen[index] == false &&
                                        openChangeStyle == true
                                    "
                                >
                                    <b-button
                                        class="item-btn"
                                        v-on:click="
                                            validateDeplacement(
                                                style.id,
                                                style.template,
                                            )
                                        "
                                        >Déplacer ici</b-button
                                    >
                                </div>
                                <div
                                    class="item-buttons"
                                    v-if="isOpen[index] == true"
                                >
                                    <b-button
                                        class="item-btn"
                                        v-on:click="newPdf(style)"
                                        >Nouveau PDF</b-button
                                    >
                                    <b-button
                                        class="item-btn"
                                        v-on:click="
                                            openModalExport(
                                                pdfList[style.id],
                                                style,
                                            )
                                        "
                                        >Exporter le groupe</b-button
                                    >
                                </div>
                                <div v-on:click="open(index)">
                                    <img
                                        src="../assets/icon/chevron-down.svg"
                                        class="logo-color"
                                        v-if="isOpen[index] == false"
                                    />
                                    <img
                                        src="../assets/icon/chevron-up.svg"
                                        class="logo-color"
                                        v-if="isOpen[index] == true"
                                    />
                                </div>
                            </div>
                            <div class="child-list">
                                <div
                                    class="child-item"
                                    v-for="pdf of pdfList[style.id]"
                                    :key="pdf.id"
                                    :class="{
                                        activeChild:
                                            activePdf[style.id][pdf.id],
                                    }"
                                >
                                    <div class="item-text">{{ pdf.name }}</div>
                                    <div class="icon-section">
                                        <img
                                            src="../assets/icon/download.svg"
                                            class="logo-color"
                                            v-on:click="downloadFile(0, pdf)"
                                        />
                                        <img
                                            src="../assets/icon/pencil.svg"
                                            class="logo-color"
                                            v-on:click="
                                                selectChild(
                                                    style.id,
                                                    pdf.id,
                                                    pdf,
                                                    style,
                                                )
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="right card" v-if="state != 0">
                <div class="head-title">
                    <span class="text-title">Propriétés du pdf</span
                    ><span class="text-description"
                        >Définissez ici les informations qui apparaitront dans
                        le PDF.</span
                    >
                </div>
                <div class="inputs-group">
                    <span class="input-label"> Nom du fichier</span
                    ><b-form-input
                        class="custom-input"
                        v-model="name"
                    ></b-form-input>
                </div>
                <div class="name-section">
                    <!-- <b-button class="name-btn">Visualiser PDF</b-button> -->
                    <b-button class="name-btn" v-on:click="downloadFile(0)"
                        >Télécharger</b-button
                    >
                    <b-button class="name-btn" v-on:click="createPdf"
                        >Sauvegarder
                        <b-spinner
                            label="Spinning"
                            style="width: 1rem; height: 1rem"
                            v-if="saveLoading"
                        ></b-spinner
                    ></b-button>
                    <b-button
                        class="name-btn"
                        v-on:click="changeStyle"
                        v-if="!openChangeStyle"
                        >Changer de style</b-button
                    >
                    <b-button
                        class="name-btn secondary"
                        v-on:click="openChangeStyle = false"
                        v-if="openChangeStyle"
                        >Terminer</b-button
                    >
                    <b-button class="name-btn" v-on:click="deletePdf"
                        >Supprimer Pdf</b-button
                    >
                </div>
                <perfect-scrollbar class="scrollable">
                    <span class="title-section">Informations générales</span>
                    <div class="inputs-group">
                        <span class="input-label">
                            <b-icon
                                icon="exclamation-circle"
                                style="color: red"
                                v-if="
                                    formConfig[styleSelect.template].title ==
                                        true && pdfData.title == ''
                                "
                            ></b-icon
                            ><b-icon
                                icon="check-circle"
                                style="color: #00817b"
                                v-if="
                                    formConfig[styleSelect.template].title ==
                                        true && pdfData.title != ''
                                "
                            ></b-icon>
                            Titre</span
                        ><b-form-input
                            class="custom-input"
                            v-model="pdfData.title"
                        ></b-form-input>
                    </div>
                    <div class="inputs-group">
                        <span class="input-label">
                            <b-icon
                                icon="exclamation-circle"
                                style="color: red"
                                v-if="
                                    formConfig[styleSelect.template].city ==
                                        true && pdfData.city == ''
                                "
                            ></b-icon
                            ><b-icon
                                icon="check-circle"
                                style="color: #00817b"
                                v-if="
                                    formConfig[styleSelect.template].city ==
                                        true && pdfData.city != ''
                                "
                            ></b-icon>
                            Ville</span
                        ><b-form-input
                            class="custom-input"
                            v-model="pdfData.city"
                        ></b-form-input>
                    </div>
                    <div class="inputs-group">
                        <span class="input-label">
                            <b-icon
                                icon="exclamation-circle"
                                style="color: red"
                                v-if="
                                    formConfig[styleSelect.template].type ==
                                        true && pdfData.type == ''
                                "
                            ></b-icon
                            ><b-icon
                                icon="check-circle"
                                style="color: #00817b"
                                v-if="
                                    formConfig[styleSelect.template].type ==
                                        true && pdfData.type != ''
                                "
                            ></b-icon>
                            Type de modèle</span
                        ><b-form-select
                            class="custom-input"
                            v-model="pdfData.type"
                            :options="typeOptions"
                        ></b-form-select>
                    </div>
                    <div class="inputs-group">
                        <span class="input-label">
                            <b-icon
                                icon="exclamation-circle"
                                style="color: red"
                                v-if="
                                    formConfig[styleSelect.template]
                                        .description == true &&
                                    pdfData.discription == ''
                                "
                            ></b-icon
                            ><b-icon
                                icon="check-circle"
                                style="color: #00817b"
                                v-if="
                                    formConfig[styleSelect.template]
                                        .description == true &&
                                    pdfData.description != ''
                                "
                            ></b-icon>
                            Description</span
                        ><b-form-textarea
                            v-model="pdfData.description"
                            class="custom-textarea"
                        ></b-form-textarea>
                    </div>
                    <div class="inputs-group">
                        <span class="input-label">
                            <b-icon
                                icon="exclamation-circle"
                                style="color: red"
                                v-if="
                                    formConfig[styleSelect.template].season ==
                                        true && pdfData.type == ''
                                "
                            ></b-icon
                            ><b-icon
                                icon="check-circle"
                                style="color: #00817b"
                                v-if="
                                    formConfig[styleSelect.template].season ==
                                        true && pdfData.type != ''
                                "
                            ></b-icon>
                            Saison</span
                        >
                        <b-form-checkbox-group
                            id="checkbox-group-1"
                            v-model="pdfData.saison"
                            :options="optionsSaison"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                        ></b-form-checkbox-group>
                    </div>
                    <span class="title-section">Contact</span>
                    <div class="inputs-group">
                        <span class="input-label">
                            <b-icon
                                icon="exclamation-circle"
                                style="color: red"
                                v-if="
                                    formConfig[styleSelect.template].ot ==
                                        true && pdfData.ot == ''
                                "
                            ></b-icon
                            ><b-icon
                                icon="check-circle"
                                style="color: #00817b"
                                v-if="
                                    formConfig[styleSelect.template].ot ==
                                        true && pdfData.ot != ''
                                "
                            ></b-icon>
                            Nom</span
                        ><b-form-input
                            class="custom-input"
                            v-model="pdfData.ot"
                        ></b-form-input>
                    </div>
                    <div class="inputs-group">
                        <span class="input-label">
                            <b-icon
                                icon="exclamation-circle"
                                style="color: red"
                                v-if="
                                    formConfig[styleSelect.template].address ==
                                        true && pdfData.address == ''
                                "
                            ></b-icon
                            ><b-icon
                                icon="check-circle"
                                style="color: #00817b"
                                v-if="
                                    formConfig[styleSelect.template].address ==
                                        true && pdfData.address != ''
                                "
                            ></b-icon>
                            Adresse</span
                        ><b-form-input
                            class="custom-input"
                            v-model="pdfData.address"
                        ></b-form-input>
                    </div>
                    <div class="inputs-group">
                        <span class="input-label">
                            <b-icon
                                icon="exclamation-circle"
                                style="color: red"
                                v-if="
                                    formConfig[styleSelect.template].phone ==
                                        true && pdfData.phone == ''
                                "
                            ></b-icon
                            ><b-icon
                                icon="check-circle"
                                style="color: #00817b"
                                v-if="
                                    formConfig[styleSelect.template].phone ==
                                        true && pdfData.phone != ''
                                "
                            ></b-icon>
                            Téléphone</span
                        ><b-form-input
                            class="custom-input"
                            v-model="pdfData.phone"
                        ></b-form-input>
                    </div>
                    <span class="title-section">Informations du parcours</span>
                    <div class="inputs-group-split">
                        <div class="inputs-left">
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .balisage == true &&
                                            pdfData.balisage == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .balisage == true &&
                                            pdfData.balisage != ''
                                        "
                                    ></b-icon>
                                    Balisage</span
                                ><b-form-select
                                    class="custom-input split"
                                    :options="balisageOptions"
                                    v-model="pdfData.balisage"
                                ></b-form-select>
                            </div>
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .distance == true &&
                                            pdfData.distance == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .distance == true &&
                                            pdfData.distance != ''
                                        "
                                    ></b-icon>
                                    Distance</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.distance"
                                ></b-form-input>
                            </div>
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .altmin == true &&
                                            pdfData.altmin == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .altmin == true &&
                                            pdfData.altmin != ''
                                        "
                                    ></b-icon
                                    >Altitude min</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.altmin"
                                ></b-form-input>
                            </div>
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .denivpositif == true &&
                                            pdfData.denivpositif == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .denivpositif == true &&
                                            pdfData.denivpositif != ''
                                        "
                                    ></b-icon>
                                    Dénivelé positif</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.denivpositif"
                                ></b-form-input>
                            </div>
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .depart == true &&
                                            pdfData.depart == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .depart == true &&
                                            pdfData.depart != ''
                                        "
                                    ></b-icon>
                                    Point de départ</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.depart"
                                ></b-form-input>
                            </div>
                            <div class="inputs-group-2" id="tooltip-target-1">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .gps == true &&
                                            pdfData.gps == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .gps == true &&
                                            pdfData.gps != ''
                                        "
                                    ></b-icon>
                                    CoordonéesGPS</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.gps"
                                    placeholder="Lon Lat exemple: 2.952014300 45.772695900"
                                ></b-form-input>
                                <b-tooltip
                                    target="tooltip-target-1"
                                    triggers="hover"
                                >
                                    'Lon Lat' exemple:
                                    <b class="text-color"
                                        >2.952014300 45.772695900</b
                                    >
                                </b-tooltip>
                            </div>
                        </div>
                        <div class="inputs-right">
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .difficulty == true &&
                                            pdfData.difficulty == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .difficulty == true &&
                                            pdfData.difficulty != ''
                                        "
                                    ></b-icon>
                                    Difficulté</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.difficulty"
                                ></b-form-input>
                            </div>
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .duration == true &&
                                            pdfData.duration == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .duration == true &&
                                            pdfData.duration != ''
                                        "
                                    ></b-icon>
                                    Durée</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.duration"
                                ></b-form-input>
                            </div>
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .altmax == true &&
                                            pdfData.altmax == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .altmax == true &&
                                            pdfData.altmax != ''
                                        "
                                    ></b-icon>
                                    Altitude max</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.altmax"
                                ></b-form-input>
                            </div>
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .denivnegatif == true &&
                                            pdfData.denivnegatif == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .denivnegatif == true &&
                                            pdfData.denivnegatif != ''
                                        "
                                    ></b-icon>
                                    Dénivelé négatif</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.denivnegatif"
                                ></b-form-input>
                            </div>
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .typeParcours == true &&
                                            pdfData.typeParcours == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .typeParcours == true &&
                                            pdfData.typeParcours != ''
                                        "
                                    ></b-icon>
                                    Type de parcours</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.typeParcours"
                                ></b-form-input>
                            </div>
                            <div class="inputs-group-2">
                                <span class="input-label">
                                    <b-icon
                                        icon="exclamation-circle"
                                        style="color: red"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .code == true &&
                                            pdfData.code == ''
                                        "
                                    ></b-icon
                                    ><b-icon
                                        icon="check-circle"
                                        style="color: #00817b"
                                        v-if="
                                            formConfig[styleSelect.template]
                                                .code == true &&
                                            pdfData.code != ''
                                        "
                                    ></b-icon>
                                    Code postale</span
                                ><b-form-input
                                    class="custom-input split"
                                    v-model="pdfData.code"
                                ></b-form-input>
                            </div>
                        </div>
                    </div>
                    <span
                        class="title-section"
                        v-if="
                            styleSelect.template == 2 ||
                            styleSelect.template == 3
                        "
                        >Images</span
                    >
                    <div class="balisage-preview">
                        <span class="input-label">Balisage selectionné</span>
                        <img
                            :src="'./balisage/' + pdfData.balisage + '.png'"
                            class="image-preview"
                        />
                    </div>
                    <div
                        class="button-edited"
                        v-if="
                            !headerEdited &&
                            (styleSelect.template == 2 ||
                                styleSelect.template == 3)
                        "
                    >
                        <b-button class="name-btn" v-on:click="editedHeader"
                            >Modifier Header</b-button
                        >
                    </div>
                    <div
                        class="inputs-group"
                        v-if="
                            headerEdited &&
                            (styleSelect.template == 2 ||
                                styleSelect.template == 3)
                        "
                    >
                        <span class="input-label">Image d'header</span>
                        <b-form-file
                            size="sm"
                            placeholder="800x200"
                            drop-placeholder="Choisir mon fichier"
                            class="custom-file"
                            v-model="header"
                        >
                        </b-form-file>
                    </div>
                    <span class="title-section">Tracé</span>
                    <div class="button-edited" v-if="!edited">
                        <b-button class="name-btn" v-on:click="editedTrace"
                            >Modifier</b-button
                        >
                    </div>
                    <div class="inputs-group" v-if="edited">
                        <span class="input-label">Tracé</span>
                        <b-form-file
                            size="sm"
                            placeholder=".Geojson .Gpx"
                            drop-placeholder="Choisir mon fichier"
                            class="custom-file"
                            v-model="trace"
                        >
                        </b-form-file>
                    </div>
                    <div class="radio-group" v-if="edited">
                        <b-form-group
                            ><b-form-radio
                                v-model="pdfData.typeMap"
                                value="lambert"
                                ><span class="input-label"
                                    >Lambert93</span
                                ></b-form-radio
                            ></b-form-group
                        >
                        <b-form-group
                            ><b-form-radio v-model="pdfData.typeMap" value="wgs"
                                ><span class="input-label"
                                    >Wgs</span
                                ></b-form-radio
                            ></b-form-group
                        >
                    </div>
                    <div
                        class="inputs-group"
                        v-if="
                            edited &&
                            (styleSelect.template == 2 ||
                                styleSelect.template == 3)
                        "
                    >
                        <span class="input-label">Orientation de la carte</span
                        ><b-form-select
                            class="custom-input"
                            v-model="pdfData.orientation"
                            :options="orientationOptions"
                        ></b-form-select>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>
<script>
import Sidebar from "@/components/layouts/Sidebar.vue";
import config from "../config";
import axios from "axios";
import { saveAs } from "file-saver";
import formConfig from "../assets/configTemplate.json";
export default {
    components: {
        Sidebar,
    },
    data: function () {
        return {
            styles: [],
            isActive: [],
            isOpen: [],
            state: 0,
            pdfData: {},
            pdfToUpdate: {},
            styleSelect: "",
            optionsSaison: [
                {
                    text: "Printemps",
                    value: 0,
                },
                {
                    text: "Été",
                    value: 1,
                },
                {
                    text: "Automne",
                    value: 2,
                },
                {
                    text: "Hiver",
                    value: 3,
                },
            ],
            typeOptions: [
                {
                    value: "RANDO",
                    text: "Randonnée",
                },
                {
                    value: "VTT",
                    text: "VTT",
                },
                {
                    value: "CYCLO",
                    text: "Cyclo",
                },
                {
                    value: "TRAIL",
                    text: "Trail",
                },
            ],
            orientationOptions: [
                {
                    value: "portrait",
                    text: "Portrait",
                },
                { value: "paysage", text: "Paysage" },
            ],
            balisageColor: {
                Balisage: "#283583",
                Balisage_Vert: "#03a65a",
                Balisage_WE: "#3fa535",
                BalisesBleu_TDS: "#585858",
                "Balise-VTT-marron-1": "#7c4608",
                GR: "#e30613",
                GTMC_remod: "#ee1c25",
                PR_Jaune: "#ffdd00",
                Renard_remod: "#f05119",
                VTTBleu_remod: "#004f91",
                VTTNoir_remod: "#000000",
                VTTRouge_remod: "#e30613",
                VTTVert_remod: "#009641",
                Papillon_remod: "#fee300",
            },
            balisageOptions: [
                {
                    value: "Balisage",
                    text: "PR Bleu",
                },
                { value: "Balisage_Vert", text: "PR Vert" },
                {
                    value: "Balisage_WE",
                    text: "Itinéraire WE",
                },
                {
                    value: "BalisesBleu_TDS",
                    text: "Balises Terres Dômes Sancy (flèches bleus)",
                },
                {
                    value: "Balise-VTT-marron-1",
                    text: "VTT Marron Marron",
                },
                {
                    value: "Balisage_VTT_Marron_FFC_Bleu",
                    text: "VTT Marron FFC Bleu",
                },
                {
                    value: "Balisage_VTT_Marron_FFC_Noir",
                    text: "VTT Marron FFC Noir",
                },
                {
                    value: "Balisage_VTT_Marron_FFC_Vert",
                    text: "VTT Marron FFC Vert",
                },
                {
                    value: "Balisage_VTT_Marron_FFC_Rouge",
                    text: "VTT Marron FFC Rouge",
                },
                {
                    value: "GR",
                    text: "GR",
                },
                {
                    value: "GTMC_remod",
                    text: "GTMC",
                },
                {
                    value: "PR_Jaune",
                    text: "PR Jaune",
                },
                {
                    value: "Renard_remod",
                    text: "Renard Orange",
                },
                {
                    value: "Balise-VTT-jaune-1",
                    text: "VTT Enduro Jaune",
                },
                {
                    value: "VTTBleu_remod",
                    text: "VTT Enduro Bleu",
                },
                {
                    value: "VTTNoir_remod",
                    text: "VTT Enduro Noir",
                },
                {
                    value: "VTTRouge_remod",
                    text: "VTT Enduro Rouge",
                },
                {
                    value: "VTTVert_remod",
                    text: "VTT Enduro Vert",
                },
                {
                    value: "Papillon_remod",
                    text: "Papillon Jaune",
                },
            ],
            trace: [],
            header: [],
            pdfList: {},
            activePdf: {},
            edited: true,
            headerEdited: true,
            saveLoading: false,
            genLoading: false,
            pdfsSelect: [],
            pdfOptions: [],
            openModal: false,
            openChangeStyle: false,
            selectChangeStyle: {},
            currentBalisage: "",
            formConfig: [],
        };
    },
    async beforeMount() {
        await this.initiateData();
        if (this.$route.params.pdf) {
            let pdf = this.$route.params.pdf;
            let style = await this.$store.dispatch("style/getStyle", {
                id: pdf.styleId,
            });
            this.selectChild(pdf.styleId, pdf.id, pdf, style);
        }
        this.formConfig = formConfig.forms;
    },
    methods: {
        open: function (index) {
            this.$set(this.isOpen, index, !this.isOpen[index]);
        },
        createPdf: async function () {
            this.saveLoading = true;

            if (this.state == 2) {
                this.pdfToUpdate.name = this.name;
                this.pdfToUpdate.data = this.pdfData;
                this.pdfToUpdate.data.name = this.name;
                this.pdfToUpdate.data.id = this.pdfToUpdate.id;
                if (
                    (this.edited == true && this.trace != "") ||
                    this.currentBalisage !== this.pdfData.balisage
                ) {
                    await this.generateMap();
                    this.pdfData.carto = true;
                }
                if (
                    this.headerEdited == true &&
                    this.header != "" &&
                    (this.styleSelect.template == 2 ||
                        this.styleSelect.template == 3)
                ) {
                    await this.generateHeader();
                    this.pdfData.header = true;
                }
                await this.$store.dispatch("pdf/save", {
                    pdfData: this.pdfToUpdate,
                });
            }
            if (this.state == 1) {
                let response = await this.$store.dispatch("pdf/create", {
                    data: this.pdfData,
                    template: this.styleSelect.template,
                    name: this.name,
                    userId: this.$store.state.user.user.id,
                    styleId: this.styleSelect.id,
                });

                this.pdfToUpdate.name = this.name;
                this.pdfToUpdate.data = this.pdfData;
                this.pdfToUpdate.data.name = this.name;
                this.pdfToUpdate.id = response.data.data.id;
                this.pdfToUpdate.data.id = response.data.data.id;
                this.pdfToUpdate.template = this.styleSelect.template;
                if (this.trace && this.pdfData.typeMap) {
                    await this.generateMap();
                    this.pdfData.carto = true;
                }
                if (
                    this.header &&
                    (this.styleSelect.template == 2 ||
                        this.styleSelect.template == 3)
                ) {
                    await this.generateHeader();
                    this.pdfData.header = true;
                }
                this.state = 2;
            }

            await this.initiateData();
            this.saveLoading = false;
            this.selectChild(
                this.styleSelect.id,
                this.pdfToUpdate.id,
                this.pdfToUpdate,
                this.styleSelect,
            );
        },
        newPdf: async function (style) {
            this.styleSelect = await this.$store.dispatch("style/getStyle", {
                id: style.id,
            });
            this.state = 1;
            this.pdfData = {};
            this.name = "";
            let activePdf = {};
            for (let el in this.pdfList) {
                activePdf[el] = {};
                for (let pdf of this.pdfList[el]) {
                    activePdf[el][pdf.id] = false;
                }
            }
            this.activePdf = activePdf;
            this.edited = true;
            this.headerEdited = true;
        },
        selectChild: async function (styleId, pdfId, pdf, style) {
            let activePdf = {};
            for (let el in this.pdfList) {
                activePdf[el] = {};

                for (let pdf of this.pdfList[el]) {
                    activePdf[el][pdf.id] = false;
                }
            }

            let temp = activePdf[styleId];

            this.$set(temp, pdfId, true);

            this.activePdf = activePdf;

            this.state = 2;
            this.name = pdf.name;
            this.pdfData = pdf.data;
            this.pdfToUpdate = pdf;
            this.currentBalisage = pdf.data.balisage;
            this.styleSelect = await this.$store.dispatch("style/getStyle", {
                id: style.id,
            });
            if (this.pdfData.carto == true) {
                this.edited = false;
            } else {
                this.edited = true;
            }
            if (this.pdfData.header == true) {
                this.headerEdited = false;
            } else {
                this.headerEdited = true;
            }
        },
        downloadFile: async function (type, pdf) {
            this.genLoading = true;
            let pdfs = [];
            if (type == 0) {
                if (pdf) {
                    this.pdfToUpdate = pdf;
                    this.styleSelect = await this.$store.dispatch(
                        "style/getStyle",
                        {
                            id: pdf.styleId,
                        },
                    );
                    this.styleSelect.name = pdf.name;
                } else {
                    await this.createPdf();
                }
                pdfs.push(this.pdfToUpdate.data);
            } else if (type == 1) {
                for (let el in this.pdfsSelect) {
                    if (this.pdfsSelect[el] == "true") {
                        this.pdfOptions[el].data.name =
                            this.pdfOptions[el].name;
                        this.pdfOptions[el].data.id = this.pdfOptions[el].id;
                        pdfs.push(this.pdfOptions[el].data);
                    }
                }
            }

            let response = await this.$store.dispatch("pdf/generate", {
                style: this.styleSelect,
                items: pdfs,
                id: this.pdfToUpdate.id,
            });
            let blob = new Blob([response.data], {
                type: "application/zip",
            });

            saveAs(blob, this.styleSelect.name + ".zip");
            this.genLoading = false;
            await this.$store.dispatch("pdf/deletepdffiles", {
                userId: this.$store.state.user.user.id,
            });
        },
        openModalExport: function (pdfs, style) {
            this.pdfOptions = pdfs;
            this.styleSelect = style;
            this.pdfsSelect = [];
            for (let i = 0; i < this.pdfOptions.length; i++) {
                this.pdfsSelect.push("true");
            }
            this.openModal = true;
        },
        initiateData: async function () {
            this.styles = await this.$store.dispatch("style/getAll", {
                userId: this.$store.state.user.user.id,
            });
            this.pdfList = await this.$store.dispatch("pdf/getPdfFiltred", {
                userId: this.$store.state.user.user.id,
            });

            for (let el in this.pdfList) {
                this.activePdf[el] = {};

                for (let pdf of this.pdfList[el]) {
                    this.activePdf[el][pdf.id] = false;
                }
            }
            for (let i = 0; i < this.styles.length; i++) {
                this.isActive.push(false);
                this.isOpen.push(false);
            }
        },
        generateHeader: async function () {
            await this.$store.dispatch("pdf/generateHeader", {
                userId: this.$store.state.user.user.id,
                genId: this.pdfToUpdate.id,
                header: this.header,
            });
        },
        generateMap: async function () {
            this.styleSelect.data = JSON.parse(this.styleSelect.data);
            if (
                this.pdfToUpdate.template == 1 ||
                this.pdfToUpdate.template == 2
            ) {
                this.styleSelect.data.color_profil_secondary =
                    this.styleSelect.data.typeColor[
                        this.pdfData.type
                    ].secondary;

                this.styleSelect.data.color_profil_primary =
                    this.styleSelect.data.typeColor[this.pdfData.type].primary;
                if (this.styleSelect.data.custom_color == "false") {
                    this.styleSelect.data.color_trace =
                        this.balisageColor[this.pdfData.balisage];
                }
            }
            let itemId = this.pdfToUpdate.id;
            if (this.edited && this.trace) {
                if (this.trace.name.search(".geojson") != -1) {
                    this.pdfData.extension = "geojson";
                } else if (this.trace.name.search(".gpx") != -1) {
                    this.pdfData.extension = "gpx";
                }
                this.pdfData.mapName = this.trace.name;
                let bodyFormData = new FormData();
                bodyFormData.append("userId", this.$store.state.user.user.id);
                bodyFormData.append("genId", this.pdfToUpdate.id);
                bodyFormData.append("name", this.trace.name);
                bodyFormData.append("file", this.trace);
                bodyFormData.append(
                    "traceColor",
                    this.styleSelect.data.color_trace,
                );
                bodyFormData.append(
                    "profilPrimaryColor",
                    this.styleSelect.data.color_profil_primary,
                );
                bodyFormData.append(
                    "profilSecondaryColor",
                    this.styleSelect.data.color_profil_secondary,
                );
                bodyFormData.append("type", this.pdfData.typeMap);
                bodyFormData.append("depart", this.pdfData.gps);
                bodyFormData.append("extension", this.pdfData.extension);
                bodyFormData.append("template", this.styleSelect.template);
                bodyFormData.append("index", itemId);
                if (
                    this.pdfToUpdate.template == 2 ||
                    this.pdfToUpdate.template == 3
                ) {
                    bodyFormData.append(
                        "orientation",
                        this.pdfData.orientation,
                    );
                }

                await axios({
                    method: "post",
                    url: `${config.api}/map/generateToPDF`,
                    data: bodyFormData,
                });
                this.styleSelect.data = JSON.stringify(this.styleSelect.data);
            }
        },
        editedTrace: function () {
            this.edited = true;
            this.pdfData.carto = false;
        },
        editedHeader: function () {
            this.headerEdited = true;
            this.pdfData.header = false;
        },
        changeStyle: function () {
            this.isOpen = [];
            for (let i = 0; i < this.styles.length; i++) {
                this.isOpen.push(false);
            }
            this.openChangeStyle = true;
        },
        validateDeplacement: async function (styleId, template) {
            await this.$store.dispatch("pdf/changeStyleId", {
                styleId: styleId,
                pdfId: this.pdfToUpdate.id,
                template: template,
            });
            await this.initiateData();
        },
        deletePdf: async function () {
            await this.$store.dispatch("pdf/deletePdf", {
                id: this.pdfToUpdate.id,
            });
            await this.initiateData();
        },
    },
};
</script>
<style>
.balisage-preview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    margin-left: 40px;
}
.image-preview {
    width: 40%;
}
.main {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    height: 100%;
    background-color: #e5e5e5;
}
.main-content {
    display: flex;
    flex-direction: rox;
    align-items: flex-start;
    gap: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    width: 100%;
}
.card {
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    height: 90%;
}
.card.left {
    width: 40%;
}
.card.right {
    width: 60%;
}
.head-title {
    display: flex;
    flex-direction: column;
    margin-left: 31px;
    margin-top: 23px;
}
.text-title {
    font-family: roboto-bold;
    font-size: 24px;
    color: #394949;
}
.text-description {
    font-family: roboto-light;
    font-size: 16px;
    color: #a8bbb5;
    margin-top: 16px;
}
.search-bar-section {
    background: #ffffff;
    box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
    border-radius: 30px;
    width: 100%;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search-bar {
    background: #f4f7fe;
    border-radius: 49px;
    width: 90%;
    height: 41px;
}
.item.open {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 15px rgba(182, 182, 182, 0.25);
    border-radius: 16px;
    width: 90%;
    height: 108px;
    padding-left: 16px;
    padding-right: 45px;
    cursor: pointer;
}
.item.base {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px rgba(10, 10, 10, 0.12);
    border-radius: 16px;
    width: 90%;
    height: 39px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 45px;
    align-items: center;
}
.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
}
.child-item {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px rgba(10, 10, 10, 0.12);
    border-radius: 16px;
    width: 85%;
    height: 39px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;

    align-items: center;
}
.logo-color {
    cursor: pointer;
}
.activeChild {
    background: #d6ebe4;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px rgba(10, 10, 10, 0.12);
    border-radius: 16px;
}
.child-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    padding-right: 45px;
}
.icon-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;
    width: 12%;
}
.group-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.item-left {
    display: flex;
    flex-direction: row;
}
.item-img {
    width: 80px;
}
.text-color {
    color: #00817b;
}
.item-text {
    font-family: roboto-medium;
    color: #00817b;
    font-size: 16px;
}
.text-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;

    margin-left: 16px;
}
.item-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}
.item-btn {
    background: #00817b;
    border-radius: 16px;
    font-size: 70%;
    font-family: roboto-light;
}
.item-btn:hover {
    background: #00817b;
}
.custom-input {
    background: #ededed;
    border: 1px solid #394949;
    border-radius: 49px;
    padding-left: 20px;
    width: 60%;
    height: 70%;
}
.custom-textarea {
    background: #ededed;
    border: 1px solid #394949;
    border-radius: 5px;
    width: 60%;
    height: 90%;
}
.name-section {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 90%;
    margin-left: 40px;
}
.inputs-group {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 40px;
}
.name-btn {
    background: #00817b;
    border-radius: 16px;
    width: 20%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: roboto-light;
}
.name-btn:hover {
    background: #00817b;
}
.input-label {
    font-family: roboto-light;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}
.title-section {
    font-family: roboto-bold;
    font-size: 20px;
    color: #00817b;
    margin-left: 31px;
}
.inputs-group-split {
    display: flex;
    flex-direction: row;
}
.inputs-left {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.inputs-right {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.inputs-group-2 {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 40px;
}
.custom-input.split {
    width: 60%;
}
.scrollable {
    display: flex;
    flex-direction: column;
}
.custom-file {
    width: 80%;
}
.radio-group {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    gap: 20px;
}
.custom-modal {
    background: #e5e5e5;
    display: flex;
    flex-direction: column;
    gap: 75px;
}
.child-list.export {
    padding-top: 70px;
    align-items: center;
}
.child-item.export {
    width: 50%;
}
.modal-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    padding-top: 70px;
}
.name-btn.secondary {
    background: #d6ebe4;
    border-color: #d6ebe4;
}
.button-edited {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 40px;
    margin-top: 10px;
}
</style>
